import {
	createApp
} from 'vue'
import App from './App.vue'
import mitt from "mitt"
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import moment from 'moment'
import Directives from './utils/index'
import 'nprogress/nprogress.css'
// 引入请求封装
import request from './api/request.js'
// 引入重置样式
import './assets/css/reset.css'
// 公共样式
import './assets/css/style.css'
// 图标
import './assets/css/iconfont.css'
// css 动画效果
import animated from 'animate.css'


//引入element-plus
import locale from 'element-plus/lib/locale/lang/zh-cn'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入富文本
import {
	QuillEditor
} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App)
app.component('QuillEditor', QuillEditor)
// 时间格式话插件
app.config.globalProperties.$Moment = moment
// 挂载事务总线
app.config.globalProperties.$EventBus = new mitt()
// this.$EventBus.emit("EVENTTYPE"); 发起
// 监听
// this.$EventBus.on("EVENTTYPE",(data)=>{})

// axios
app.config.globalProperties.$http = request
app.use(Directives)
app.use(animated)
app.use(store)
app.use(router)

// 新增代码：注册全部组件
app.use(ElementPlus, {
	locale
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
	if (sessionStorage.getItem('token') || to.path == '/login') {

		next()
	} else {
		next({
			path: '/login'
		})
	}
})
app.use(ElementPlus)
app.mount('#app')
