import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
		path: "/",
		name: "index",
		component: () => import('@/pages/layout/index.vue'),
		children: [{
				path: "/",
				name: "数据统计",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/index/index.vue")
			},
			{
				path: "/userlist",
				name: "账户列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/user/index.vue")
			},
			{
				path: "/clique",
				name: "党员列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/clique/index.vue")
			},
			{
				path: "/livegood",
				name: "直播商品列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/live/livegood/index.vue")
			},
			{
				path: "/live",
				name: "直播列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/live/index.vue")
			},
			{
				path: "/importGood",
				name: "导入商品",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/live/importGood/index.vue")
			},
			{
				path: "/stream",
				name: "流动党员列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/stream/index.vue")
			},
			{
				path: "/cliqueDatas",
				name: "个人资料",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/clique/cliqueDatas/index.vue")
			},
			{
				path: "/dynamics",
				name: "党员动态",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/clique/dynamics/index.vue")
			},
			{
				path: "/config",
				name: "系统配置",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/config/index.vue")
			},
			{
				path: "/stafflist",
				name: "用户列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/staff/index.vue")
			},
			{
				path: "/good",
				name: "商品列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/good/index.vue")
			},
			{
				path: "/bonus",
				name: "奖补列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/bonus/index.vue")
			},
			{
				path: "/goout",
				name: "外出务工奖补申请列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/bonus/goout/index.vue")
			},
			{
				path: "/give",
				name: "外出务工交通补助申请列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/bonus/give/index.vue")
			},
			{
				path: "/estate",
				name: "产业奖补申请列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/bonus/estate/index.vue")
			},
			{
				path: "/goodType",
				name: "商品分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/good/type/index.vue")
			},
			{
				path: "/sonType",
				name: "商品二级分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/good/sonType/index.vue")
			},
			{
				path: "/spec",
				name: "规格列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/good/spec/index.vue")
			},
			{
				path: "/rolelist",
				name: "角色列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/role/index.vue")
			},
			{
				path: "/work",
				name: "队长列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/work/index.vue")
			},
			{
				path: "/feedback",
				name: "人居环境",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/feedback/index.vue")
			},
			{
				path: "/target",
				name: "目标列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/work/target/index.vue")
			},
			{
				path: "/village",
				name: "村列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/village/index.vue")
			},
			{
				path: "/heart",
				name: "感悟列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/heart/index.vue")
			},
			{
				path: "/order",
				name: "订单列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/order/index.vue")
			},
			{
				path: "/integralOrder",
				name: "积分兑换商品列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/integralOrder/index.vue")
			},
			{
				path: "/integralGood",
				name: "兑换商品列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/integralOrder/good/index.vue")
			},
			{
				path: "/refund",
				name: "退款订单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/order/refund/index.vue")
			},
			{
				path: "/info",
				name: "用户资料",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/info/index.vue")
			},
			{
				path: "/menu",
				name: "菜单列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/index.vue")
			},
			{
				path: "/level",
				name: "二级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/level/index.vue")
			},
			{
				path: "/news",
				name: "资讯列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/news/index.vue")
			},
			{
				path: "/newstype",
				name: "资讯分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/news/type/index.vue")
			},
			{
				path: "/approve",
				name: "审批列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/approve/index.vue")
			},
			{
				path: "/approveTitle",
				name: "业务办理项",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/approve/title.vue")
			},
			{
				path: "/classroom",
				name: "党课堂列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/classroom/index.vue")
			},
			{
				path: "/partyOrder",
				name: "已缴党费订单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/party/index.vue")
			},
			{
				path: "/banner",
				name: "轮播列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/banner/index.vue")
			},
			{
				path: "/salary",
				name: "党费工资区间列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/party/salary/index.vue")
			},
			{
				path: "/integral",
				name: "党课堂积分段设置",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/classroom/integral/index.vue")
			},
			{
				path: "/classroomType",
				name: "党课堂分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/classroom/type/index.vue")
			},
			{
				path: "/approveType",
				name: "审批分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/approve/type/index.vue")
			},
			{
				path: "/travel",
				name: "旅游列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/travel/index.vue")
			},
			{
				path: "/money",
				name: "招商列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/money/index.vue")
			},
			{
				path: "/villageInfo",
				name: "村信息录入",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/villageInfo/index.vue")
			},
			{
				path: "/important",
				name: "村重要人群录入",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/villageInfo/important/index.vue")
			},
			{
				path: "/farm",
				name: "大棚列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/farm/index.vue")
			},
			{
				path: "/activity",
				name: "党建活动列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/activity/index.vue")
			},
			{
				path: "/mail",
				name: "通讯录列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/mail/index.vue")
			},
			{
				path: "/signup",
				name: "党建活动报名人员",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/activity/signup/index.vue")
			},
			{
				path: "/menubtn",
				name: "三级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/btn/index.vue")
			},
			{
				path: "/pubbtn",
				name: "按钮列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/pubbtn/index.vue")
			},
			{
				path: '/:pathMatch(.*)*',
				name: '404',
				component: () => import('../views/404/index.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue')
	}

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	//  记录位置
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				top: 0
			}
		}
	},
	routes
})

export default router
